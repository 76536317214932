/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../../components/Layout'
import ThemeWrapper from '../../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Subtitle, Text, ContactForm, Button } from '@swp/components'
import SiteHeader from '../../components/de/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"Kontakt"}>
        <SiteHeader />

        <Column className="mt--30 pb--0 pt--0" name={"kontakt"}>
          
          <ColumnWrap className="column__flex --shape4 --center el--2 pb--60 pl--0 pr--0 pt--50 flex--top" anim={null} animS={null} style={{"backgroundColor":"var(--color-custom-1)"}} columns={"2"}>
            
            <ColumnWrapper className="js-anim  --anim5 --anim-s3" anim={"5"} animS={"3"} style={{"maxWidth":510}}>
              
              <Title className="title-box title-box--left fs--62 title-box--invert" content={"Kontakt"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper className="--left" anim={null} animS={null} style={{"maxWidth":510}}>
              
              <Subtitle className="subtitle-box subtitle-box--invert lh--16" content={"+49 797 811 2X05<br>info@vase-stranky.com"}>
              </Subtitle>

              <Text className="text-box text-box--invert" content={"Fangen Sie an Ihren Text zu schreiben. Dieser Bereich ist für Ihren Text bestimmt. Bei Bedarf können Sie die Farbe des Textblocks, die Schriftgröße und den Zeilenabstand ändern oder den Text ausrichten."}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="js-anim  --anim5 --anim-s3 pb--80 pt--80" anim={"5"} name={"kontakt-2"} animS={"3"}>
          
          <ColumnWrap className="column__flex el--2 pb--06 pl--02 pr--02 pt--06 flex--top" style={{"maxWidth":1400}} columns={"2"}>
            
            <ColumnWrapper style={{"maxWidth":520}}>
              
              <Title className="title-box" content={"Kontaktformular"}>
              </Title>

              <Text className="text-box" content={"Fangen Sie an Ihren Text zu schreiben. Dieser Bereich ist für Ihren Text bestimmt. Bei Bedarf können Sie die Farbe des Textblocks, die Schriftgröße und den Zeilenabstand ändern oder den Text ausrichten."}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <ContactForm className="--shape2 --shape2 --style1 --right fs--18 lh--2" action={"/contact"} fields={[{"name":"Ganze Name","type":"text","required":true,"placeholder":"Bitte tragen Sie Ihren Namen ein"},{"name":"E-Mail Kontakt:","type":"email","required":true,"placeholder":"Bitte geben Sie Ihre e-Mail Adresse ein"},{"name":"Telefon Nr.","type":"text","placeholder":"Telefon Nr."},{"name":"Nachricht","type":"textarea","required":true,"placeholder":"Bitte geben Sie Text Ihrer Nachricht ein"},{"name":"Versenden","type":"submit","align":"left"}]}>
              </ContactForm>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--80 pt--0" name={"lw60z5h5vx"}>
          
          <ColumnWrap className="column__flex --shape4 --center el--2 pb--50 pl--0 pr--0 pt--50 flex--top" anim={null} animS={null} style={{"backgroundColor":"var(--color-blend--95)"}} columns={"2"}>
            
            <ColumnWrapper className="js-anim  --anim5 --anim-s3" anim={"5"} animS={"3"} style={{"maxWidth":510}}>
              
              <Title className="title-box title-box--left" content={"Haben sie interesse an der professionellen Reinigung?"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper anim={null} animS={null} style={{"maxWidth":510}}>
              
              <Button className="btn-box btn-box--shape3 btn-box--right" href={"/de/kontakt"} content={"Kontaktieren Sie uns"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"partei"} style={{"backgroundColor":"var(--color-custom-1)"}}>
          
          <ColumnWrap className="column__flex el--2 mb--20 mt--10 pb--06 pl--02 pr--02 pt--06" style={{"maxWidth":1400}} columns={"2"}>
            
            <ColumnWrapper className="--left" style={{"maxWidth":""}}>
              
              <Title className="title-box fs--24 title-box--invert" style={{"maxWidth":""}} content={"Professionelle Reinigungsfirma Olomouc"}>
              </Title>

              <Text className="text-box text-box--invert" content={"Fangen Sie an Ihren Text zu schreiben. Dieser Bereich ist für Ihren Text bestimmt. Bei Bedarf können Sie die Farbe des Textblocks, die Schriftgröße und den Zeilenabstand ändern oder den Text ausrichten."}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--right" style={{"maxWidth":""}}>
              
              <Title className="title-box fs--24 title-box--invert" style={{"maxWidth":""}} content={"Kontakt"}>
              </Title>

              <Text className="text-box text-box--invert" content={"+49 797 811 2X05<br>info@vase-stranky.com"}>
              </Text>

              <Text className="text-box text-box--invert" content={"<a href=\"https://saywebpage.com\">Webproduktion stránek</a>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}